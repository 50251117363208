import React, { useState } from "react";
import Layout from "../components/layout/layout";
import Seo from "../components/layout/seo";
import styled from "styled-components";
import PDF from "../../static/images/meniu/meniuen.pdf";

import "./PdfPage.css";
import { Document, Page, pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const MeniuEn = () => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }

  function changePage(offSet) {
    setPageNumber((prevPageNumber) => prevPageNumber + offSet);
  }

  function changePageBack() {
    changePage(-1);
  }

  function changePageNext() {
    changePage(+1);
  }
  return (
    <Layout>
      <Seo title="Meniu" />
      <PdfWrapper>
        <div id="ResumeContainer">
          <Document
            className={"PDFDocument"}
            file={PDF}
            onLoadSuccess={onDocumentLoadSuccess}
          >
            <Page
              className={"PDFPage PDFPageOne"}
              pageNumber={pageNumber}
              renderTextLayer={false}
              renderInteractiveForms={false}
            />
          </Document>
        </div>
      </PdfWrapper>
      <InfoText>
        Pagina {pageNumber} din {numPages}
      </InfoText>
      <Butoane>
        {pageNumber > 1 && <Buton onClick={changePageBack}>Previous</Buton>}
        {pageNumber < numPages && (
          <Buton onClick={changePageNext}>Next Page</Buton>
        )}
      </Butoane>
      <ValoriDiv>
        <a href="https://valori-nutritionale.ro/restaurant/garlic-bites-tales&utm=qrcode/">
          <Valori>Nutritional values</Valori>
        </a>
      </ValoriDiv>
    </Layout>
  );
};

export default MeniuEn;

const ValoriDiv = styled.div`
  justify-content: center;
  display: flex;
`;

const Valori = styled.div`
  width: 300px;
  background-color: #ffffff;
  padding: 10px;
  color: #1a1f25;
  border-radius: 10px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 700;
  margin-top: 25px;
  text-align: center;
`;

const InfoText = styled.p`
  color: white;
  opacity: 0.6;
  text-align: center;
`;

const Buton = styled.div`
  background-color: #ffffff;
  padding: 10px;
  color: #1a1f25;
  border-radius: 10px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 700;
  margin-top: 25px;
  max-width: 150px;
  margin: 10px;
  border: 1px white solid;
  cursor: pointer;

  :hover {
    background-color: rgba(0, 0, 0, 0);
    border: 1px white solid;
    color: white;
    transform: scale(1.1);

    transition: all 0.8s cubic-bezier(0.075, 0.82, 0.165, 1) 0s;
    transition-property: all;
    transition-duration: 0.8s;
    transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
    transition-delay: 0s;
  }
`;

const Butoane = styled.div`
  display: flex;
  justify-content: center;
  // flex-direction: column;
  margin-bottom: 100px;
`;
const PdfWrapper = styled.div`
  margin-top: 150px;
`;
